import { get, post } from '../../utils/http';

export const getCaiHistory = async (params: any) => {
  const res = await get('api/caiHistory/getCaiHistory', params);
  return res;
};

export const getMsgChatList = async (params: any) => {
  const res = await post('api/caiHistory/getMsgChatList', params);
  return res || {};
};

export const getMsgInfoList = async (params: any) => {
  const res = await post('api/caiHistory/getMsgInfoList', params);
  return res || {};
};

export const getCaiBotInfo = async (params: any) => {
  const res = await get('api/caiHistory/getCaiBotInfo', params);
  return res;
};

export const updateCaiHistory = async (params: any) => {
  const res = await post('api/caiHistory/updateCaiHistory', params);
  return res;
};
