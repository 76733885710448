import queryString from 'query-string';

/* 跳转路由 */
export const openUrl = (url: string) => {
  try {
    if (!url || url === 'undefined') {
      throw new Error(`openUrl missed url,url:${url}`);
    }

    window.location.href = url;
  } catch (error) {
    console.log(error);
  }
};

export const getUrlParams = (url: string) => {
  const params = queryString.parse(url.split('?')[1]);
  return params;
};
