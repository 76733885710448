import React from 'react';
import { Divider, Popover } from 'antd';
import styles from './index.module.less';
import ppo from 'ppo';
import { getIsWxLogin, getIsRtxLogin, getIsPswLogin } from 'utils/common';
import { useConcent } from 'concent';
import { LOGIN_TYPE } from 'configs/constant/common';

function UserInfo() {
  const userCtx = useConcent({ module: 'user' });
  const { userId, accountType, userName } = userCtx.state;
  const isWxLogin = getIsWxLogin(localStorage.getItem('UG_CAIDATA_LOGIN_TYPE') || '');
  const localOpenId = (isWxLogin && localStorage.getItem('UG_CAIDATA_OPENID')) || '';
  const localNickName = (isWxLogin && localStorage.getItem('UG_CAIDATA_NAME')) || '';
  const isRtxLogin = getIsRtxLogin(accountType);
  const isPswLogin = getIsPswLogin(accountType);
  const logoutWxAccount = () => {
    localStorage.removeItem('UG_CAIDATA_OPENID');
    localStorage.removeItem('UG_CAIDATA_NAME');
    localStorage.removeItem('UG_CAIDATA_AVATAR');
    localStorage.removeItem('UG_CAIDATA_LOGIN_TYPE');
    // window.location.reload();
    window.location.href = `${window.location.origin}/#/login`;
    // window.location.reload();
    // navigate('/login');
  };

  const logoutRtxAccount = () => {
    ppo.delCookie('leah_auth');
    ppo.delCookie('leah_user');

    localStorage.removeItem('UG_CAIDATA_LOGIN_TYPE');
    // navigate('/login');
    window.location.href = `${window.location.origin}/#/login`;
    // window.location.reload();
    // const url = window.location.href.split('?')[0];
    // const href = `http://passport.woa.com/modules/passport/signout.ashx?url=${encodeURIComponent(url)}`;
    // window.location.href = href;
  };

  const logoutPswAccount = () => {
    localStorage.removeItem('UG_CAIDATA_LOGIN_TYPE');

    window.location.href = `${window.location.origin}/#/login`;
  };

  const exit = () => {
    userCtx.setState({
      userName: '',
      userId: '',
      avatar: '',
      accountType: '',
    });
    // ppo.delCookie('user-id');
    // ppo.delCookie('userTicket');
    // 3个账号联合登录的退出登录
    isWxLogin && logoutWxAccount();
    isRtxLogin && logoutRtxAccount();
    isPswLogin && logoutPswAccount();
  };

  const content =
    userId || localOpenId ? (
      <div className={styles.user_info}>
        <span className={styles.account}>
          账号ID
          <br />
          <span className={styles.num}>{userId || localOpenId}</span>
        </span>
        <a onClick={exit}>退出登录</a>
      </div>
    ) : (
      '登录后可体验'
    );

  // const avatar = data.avatar ? `http:${data.avatar}` : 'https://upage.imtt.qq.com/m_imtt/wx-quiz/images/avatar-back.png';
  const avatar =
    accountType === LOGIN_TYPE.rtx
      ? `http://r.hrc.woa.com/photo/150/${userId}.png`
      : 'https://upage.imtt.qq.com/m_imtt/wx-quiz/images/avatar-back.png';
  // console.log('LOGIN_TYPE.rtx', accountType);
  // 这里不上报，避免登录过期重定向到登录抛出接口到toast提示
  // if (!data.userName && localOpenId && !nickname) {
  //   reportAct({
  //     subpos: PAGE_NAME_MAP.noAuth,
  //     acttype: 2, // 曝光是2，点击是3
  //     extra: {
  //       userInfo: JSON.stringify(data),
  //       userId: localOpenId,
  //     },
  //   });
  // }

  return (
    <Popover content={content}>
      <div className={styles.user_info_content}>
        {/* <Avatar size="small" src= /> */}
        <img className={styles.avatar} src={avatar}></img>
        <Divider type="vertical" />
        {userName || (localOpenId ? localNickName || '未授权' : userId || '未登录')}
      </div>
    </Popover>
  );
}

export default UserInfo;
