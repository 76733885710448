import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import type { MenuTheme } from 'antd';
import styles from './index.module.less';
import { getIsQqDomain } from 'utils/common';

const { Item } = Menu;
// 查找children里第一个path
const getPath = (menus = []) => {
  while (menus.length > 0) {
    const { path, children, target } = menus[0];
    if (path) return { path, target };
    menus = children;
  }
  return {
    path: 'not-found',
    target: '',
  };
};

// 对外删除不可见菜单
const deepDelete = (arr: any[], authList: any) => {
  for (let i = arr.length - 1; i >= 0; i--) {
    // 符合某个条件的删除
    const checkAuth = arr[i]?.checkAuth;
    const isQqDomianCheck = arr[i]?.isQqDomianCheck;
    const isCheckAuthPass =
      checkAuth?.length > 0
        ? checkAuth?.findIndex((auth: any) => {
            return (auth === 0 || auth) && authList?.length > 0 && authList?.includes(auth);
          }) > -1
        : true;
    const isNoCheckDomain = isQqDomianCheck && getIsQqDomain();
    const isNoAuth = (authList?.length > 0 && !isCheckAuthPass) || isNoCheckDomain;
    if (typeof arr[i] === 'object' && arr[i] !== null && isNoAuth) {
      arr.splice(i, 1);
    } else {
      if (arr[i]?.children) {
        deepDelete(arr[i]?.children, authList);
      }
    }
  }
};

const HeadMenu = ({ authList = [], userId = '', menus = [], selectedKey = '', theme = 'light' as MenuTheme }) => {
  const userMenus: any = JSON.parse(JSON.stringify([...menus]));
  // 外部用户不跳转该路径
  deepDelete(userMenus, authList);
  // 未登录不展示
  if (!userId) {
    return null;
  }
  return (
    <Menu mode="horizontal" theme={theme} className={styles.head_menu} selectedKeys={[selectedKey]}>
      {userMenus.map(({ name = '', path = '', children = [], target = '' }) => {
        const child = getPath(children);
        return (
          <Item key={name}>
            <Link to={path || child.path || ''} target={target || child.target}>
              <span>
                {name}
                {target}
              </span>
            </Link>
          </Item>
        );
      })}
    </Menu>
  );
};

export default HeadMenu;
