export const DATE_FORMAT_TIME = 'YYYY-MM-DD HH:mm:ss';

export const PANGU_IDS = {
  development: 1522,
  production: 1521,
};
export const PANGU_TEMPLATE_IDS = {
  development: 1927,
  production: 1928,
};

export const CURRENT_MAINTAINER = 'mclaneyu';

// 本地缓存上一次的登录类型，控制进入wx、rtx、psw登录界面
export const LOGIN_TYPE = {
  wx: 'wx',
  rtx: 'rtx',
  psw: 'psw',
};

export const PAGE_NAME_MAP = {
  myTasks: 'myTasks',
  checkTasks: 'checkTasks',
  checkedTasks: 'checkedTasks',
  reviewTasks: 'reviewTasks',
  projectList: 'projectList',
  samplingTasks: 'samplingTasks',
  iframe: 'iframe',
  botsList: 'botsList',
  qaList: 'qaList',
  noAuth: 'noAuth',
  caiBlindReview: 'caiBlindReview',
  qaReview: 'qaReview',
};

export enum ReportActId {
  CAIDATA_SYS_DATA = 'caidata_sys_data', // 系统数据上报使用-数据看板的数据统计
}

// 系统数据上报节点
export enum ReportActStatus {
  LOGIN = 0, // 登录
  MARK = 1, // 生产
  REVIEW = 2, // 质检
  SAMPLING = 3, // 抽样
  CHECK = 4, // 验收
  CHECKED = 5, // 重新验收
}

export const USER_AUTH_MAP = {
  admin: 1,
  external: 2,
};

// 盘古权限映射map
export const ALL_PANGU_AUTH_ID_MAP: any = {
  development: {
    view_all_task_list: 145329, // 查看/管理所有人的项目/任务列表
    view_all_sys_data: 144849, // 查看系统数据
    mark: 144845, // 生产
    review: 144846, // 质检
    sample: 144847, // 抽检
    check: 144848, // 验收
    manage_project: 144844, // 项目/任务管理
    create_project: 144843, // 创建项目
    tool_config: 144842, // 工具配置
    create_bot: 144841, // 人设导入
    cai_blind_review: 146381, // 盲评
    qa_review: 148343, // 对比复评
    approve_auth: 147069, // 权限审批
  },
  production: {
    view_all_task_list: 145914, // 查看/管理所有人的项目/任务列表
    view_all_sys_data: 145913, // 查看系统数据
    mark: 145909, // 生产
    review: 145910, // 质检
    sample: 145911, // 抽检
    check: 145912, // 验收
    manage_project: 145908, // 项目/任务管理
    create_project: 145907, // 创建项目
    tool_config: 145906, // 工具配置
    create_bot: 144914, // 人设导入
    cai_blind_review: 146383, // 盲评
    qa_review: 148344, // 对比复评
    approve_auth: 147070, // 权限审批
  },
};
// 盘古角色映射map-微信暴露
export const ALL_WX_PANGU_ROLE_ID_MAP: any = {
  development: {
    mark: { value: 281963, label: '生产者' }, // 生产
    review: { value: 281964, label: '质检者' }, // 质检
    blind_review: { value: 285687, label: '模型盲评者' }, // 模型盲评
  },
  production: {
    mark: { value: 284945, label: '生产者' }, // 生产
    review: { value: 284946, label: '质检者' }, // 质检
    blind_review: { value: 285684, label: '模型盲评者' }, // 模型盲评
  },
};

// 盘古角色映射map-rtx暴露
export const ALL_RTX_PANGU_ROLE_ID_MAP: any = {
  development: {
    pro_operator: { value: 284949, label: '开发运营' }, // 技术运维/产品运营
    blind_review: { value: 285687, label: '模型盲评者' }, // 模型盲评
    mark: { value: 281963, label: '生产者' }, // 生产
    review: { value: 281964, label: '质检者' }, // 质检
    sampling: { value: 281965, label: '抽检者' }, // 抽检
    check: { value: 281966, label: '验收者' }, // 验收
    project_manager: { value: 281962, label: '项目经理' }, // 项目经理
  },
  production: {
    blind_review: { value: 285684, label: '模型盲评者' }, // 模型盲评
    pro_operator: { value: 284950, label: '开发运营' }, // 技术运维/产品运营
    mark: { value: 284945, label: '生产者' }, // 生产
    review: { value: 284946, label: '质检者' }, // 质检
    sampling: { value: 284947, label: '抽检者' }, // 抽检
    check: { value: 284948, label: '验收者' }, // 验收
    project_manager: { value: 284944, label: '项目经理' }, // 项目经理
  },
};

const env = process.env.NODE_ENV?.toLowerCase();
export const PANGU_AUTH_ID_MAP = ALL_PANGU_AUTH_ID_MAP[env];
export const PANGU_WX_ROLE_ID_MAP: any = ALL_WX_PANGU_ROLE_ID_MAP[env];
export const PANGU_RTX_ROLE_ID_MAP: any = ALL_RTX_PANGU_ROLE_ID_MAP[env];

export const USER_STATUS_MAP = {
  error: -1,
  noUser: 0, // redis无用户
  unApprovedInRedis: 1, // redis用户表未审批
  noAuthInPangu: 2, // 盘古未授权
  approvedInRedis: 3, // 盘古未授权
  approved: 4, // 盘古已授权
  rejected: 5, // 被redis审批驳回
  isExpired: 6, // 登录过期
};

export const REDIS_USER_LOGIN_TYPE_MAP = { rtx: 1, wx: 2 };

export const REDIS_USER_LOGIN_TYPE = [
  {
    label: 'wx',
    value: REDIS_USER_LOGIN_TYPE_MAP.wx,
  },
  {
    label: 'rtx',
    value: REDIS_USER_LOGIN_TYPE_MAP.rtx,
  },
];

export const QAREVIEW_COLOUMN_MAP = {
	task_type: {
		nameZH: '任务类型',
		type: 'select',
		options: {
			1: '盲评',
			2: '竞品',
		},
	},
}
export enum OcrPlatForm {
  PF_XINGYE = 1, // 星野
  PF_MAOXIANG = 2, // 猫箱
  PF_WX = 3, // 微信
  PF_YUANBAO = 4, // 元宝
}

// 语料oct截图来源平台
export const ocrPlatformList = [
  { value: OcrPlatForm.PF_XINGYE, label: '星野' },
  { value: OcrPlatForm.PF_MAOXIANG, label: '猫箱' },
  { value: OcrPlatForm.PF_WX, label: '微信' },
  { value: OcrPlatForm.PF_YUANBAO, label: '元宝' },
];
