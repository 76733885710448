import React from 'react';
import { Layout, Tooltip } from 'antd';
import UserInfo from 'components/UserInfo';
import HeadMenu from 'components/HeadMenu';
import { useConcent } from 'concent';
import styles from './index.module.less';
import ButtonLink from 'components/ButtonLink';
import { CURRENT_MAINTAINER } from 'configs/constant/common';
import { getIsQqDomain, getIsRtxLogin } from 'utils/common';
const { Header } = Layout;

const userItemGap = 24;

interface Props {
  menus: any;
  selectedKey: string;
  pathname: string;
}

function Headers(props: Props) {
  const userCtx = useConcent({ module: 'user' });
  const { menus, selectedKey } = props;
  const { userId, accountType, authList } = userCtx.state;
  const onLogoClick = () => {
    if (!userId) {
      return;
    }
    window.location.href = `${window.location.origin}/`;
  };

  const isRtxLogin = getIsRtxLogin(accountType);

  // const changeRole = (val: string) => {
  //   console.log('rorororo', val);
  //   userCtx.setState({
  //     project: val,
  //   });
  // };

  return (
    <Header className={styles.head_box}>
      <div className={styles.logo_cont} onClick={onLogoClick}>
        {/* <img alt="logo" src="https://kandian-1258344701.file.myqcloud.com/caidata_admin/logo_v2.jpg" className={styles.logo_img} /> */}
        <h3 className={styles.title}>语料生产系统</h3>
      </div>

      {authList?.length > 0 && userId ? <HeadMenu menus={menus} selectedKey={selectedKey} authList={authList} userId={userId}></HeadMenu> : null}

      {/* 暂时隐藏，后续后台能力ready再展示出来即可 */}
      <div className={styles.user_box}>
        {authList?.length > 0 ? (
          <>
            {userId && !getIsQqDomain() ? (
              <ButtonLink
                isHttpUrl={true}
                style={{ paddingLeft: userItemGap, paddingRight: userItemGap }}
                antdOptions={{
                  href: 'https://doc.weixin.qq.com/doc/w3_AVYAJwa1AJgC91y1sDVSveqmddhzR?scode=AJEAIQdfAAoqqnMFlYAVYAJwa1AJg',
                  target: '_blank',
                }}
              >
                使用文档
              </ButtonLink>
            ) : null}

            {userId && isRtxLogin && !getIsQqDomain() ? (
              <span className={styles.desc_text}>
                <Tooltip mouseEnterDelay={0} className="feedback" title={`请企业微信联系 ${CURRENT_MAINTAINER}`}>
                  <a href={`wxwork://message?username=${CURRENT_MAINTAINER}`}>意见反馈</a>
                </Tooltip>
              </span>
            ) : null}
          </>
        ) : null}
        {/* <span className={styles.desc_text}>权限</span> */}

        <UserInfo></UserInfo>
      </div>
    </Header>
  );
}

export default Headers;
