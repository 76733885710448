const getInitialState = () => ({
  // woa登录信息
  avatar: '',
  login: false,
  user: '', // 用户昵称，微信一开始没有真实名称，先把昵称保留在这
  // auth: null, // 设置null，区分没有请求回来的初始态
  project: '',
  openId: '',

  // 统一用户管理表的用户信息
  userName: '',
  userId: '',
  accountType: '',
  defaultAppId: null,
  authList: null, // 设置null，区分没有请求回来的初始态
});
export default getInitialState();
