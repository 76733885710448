import { message } from 'antd';
import { IModuleCtx } from 'models';

import { get, post } from '../../utils/http';

export const getCaiToken = async (params: any, moduleState: any, actionCtx: IModuleCtx) => {
  const res = await get('api/external/getCaiToken', params);
  actionCtx.setState({ ...moduleState, ...res });

  return { ...res };
};

export const reportAct = async (params: any) => {
  // params = { qua, subpos, acttype, extra }
  // qua: 客户端信息，一般是navigator.userAgent
  // subpos: 具体在哪个页面，子运营位
  // acttype: 动作，曝光是2，点击是3
  // extra: 拓展信息
  const paramsAll = {
    qua: navigator.userAgent,
    ...params,
  };
  const res = await post('api/external/reportAct', paramsAll);
  // actionCtx.setState({ ...moduleState, ...res });

  return { ...res };
};

export const getCompareQAReviewTask = async (params: any) => {
  try {
    const res = await post('api/external/getCompareQAReviewTask', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const getQAReviewTaskChats = async (params: any) => {
  try {
    const res = await post('api/external/getQAReviewTaskChats', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const reviewEvalChats = async (params: any) => {
  try {
    const res = await post('api/external/reviewEvalChats', params);
    return res;
  } catch (error) {
    message.error(error.message);
  }
}

export const uploadImage = async (params: any) => {
  try {
    const res = await post('api/external/uploadImage', params);
    return res;
  } catch (error) {
    return false;
  }
};
