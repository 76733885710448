import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import type { MenuTheme } from 'antd';
import { getIsQqDomain } from 'utils/common';
const { Item, SubMenu } = Menu;

// 查找children里第一个path
// const getPath = (menus = []) => {
//   while (menus.length > 0) {
//     const { path, children } = menus[0];
//     if (path) return path;
//     menus = children;
//   }
//   return 'not-found';
// };

// 菜单项目
const menuItems = (menus: any[], { authList }: any) =>
  //   menus.map(({ name, path, children }) => (
  //     <Item key={name}>
  //       <Link to={path || getPath(children)}>
  //         <span>{name}</span>
  //       </Link>
  //     </Item>
  //   ));
  // };

  menus.map(
    (item: {
      name: any;
      path: any;
      children: any;
      isOuterLink: any;
      isTab: any;
      link: any;
      target: string;
      checkAuth: any;
      isQqDomianCheck: boolean;
    }) => {
      // const { name, path, children, isOuterLink, isTab, link } = item;
      const { name, path, children, target = '', checkAuth = [], isQqDomianCheck = false } = item;
      const title = (
        <span>
          <span>{name}</span>
        </span>
      );
      // if (isOuterLink) {
      //   return (
      //     <Item key={path}>
      //       <div
      //         className="row-start-flex"
      //         onClick={() => {
      //           const businessId = isTab ? rest.product2Tab[rest.productid] || '8899' : '';
      //           window.open(`${link}${businessId}`, '_blank');
      //         }}
      //       >
      //         {name}
      //         <img className="slide-menu-icon" style={{ marginLeft: 5 }} src={share} />
      //       </div>
      //     </Item>
      //   );
      // }
      // 权限限制用户不展示风险菜单
      const isCheckAuthPass =
        checkAuth?.length > 0
          ? checkAuth?.findIndex((auth: any) => {
              return (auth === 0 || auth) && authList?.length > 0 && authList?.includes(auth);
            }) > -1
          : true;
      const isNoCheckDomain = isQqDomianCheck && getIsQqDomain();
      const isNoAuth = (authList?.length > 0 && !isCheckAuthPass) || isNoCheckDomain;

      if (isNoAuth) {
        return null;
      }

      if (children && !path) {
        return (
          <SubMenu key={name} title={title}>
            {menuItems(children, { authList })}
          </SubMenu>
        );
      }
      return (
        <Item key={path}>
          <Link to={path} target={target}>
            {title}
          </Link>
        </Item>
      );
    },
  );

const SideMenu = ({ userInfo = {}, pathname = '', openKeys: openKeysArr = [] as any, menus = [] as any, theme = 'light' as MenuTheme }) => {
  const [openKeys, setOpenKeys] = useState(openKeysArr || []);
  useEffect(() => {
    setOpenKeys(openKeysArr);
  }, [openKeysArr]);
  return (
    <Menu
      mode="inline"
      theme={theme}
      style={{ width: '100%' }}
      selectedKeys={[pathname]}
      openKeys={openKeys}
      onOpenChange={(openKeys) => setOpenKeys(openKeys)}
    >
      {menuItems(menus, { ...userInfo })}
    </Menu>
  );
};

export default SideMenu;
