import { IModuleCtx } from 'models';
import { message } from 'antd';

import { get, post } from '../../utils/http';

export const getExternalLoginConfig = async (params: any, moduleState: any, actionCtx: IModuleCtx) => {
  const res = await get('api/user/getExternalLoginConfig', params);
  actionCtx.setState({ ...moduleState, ...res });

  return { ...res };
};

export const getWxUserInfo = async (params: any, moduleState: any, actionCtx: IModuleCtx) => {
  const res = await get('api/user/getWxUserInfo', params);
  actionCtx.setState({ ...moduleState, user: res.nickname, login: !!res?.nickname, avatar: res.headimgurl, openId: res.openid });
  return { ...res };
};

export const getUserInfoByOpenid = async (params: any, moduleState: any, actionCtx: IModuleCtx) => {
  const res = await get('api/user/getUserInfoByOpenid', params);
  actionCtx.setState({ ...moduleState, user: res.nickname, login: !!res?.nickname, avatar: res.headimgurl, openId: res.openid });

  return { ...res };
};

// rtx登录
export const woaLogin = async (params: any, moduleState: any, actionCtx: IModuleCtx) => {
  try {
    const res = await post('api/user/woaLogin', params);
    actionCtx.setState({
      // woa登录信息
      avatar: `http://r.hrc.woa.com/photo/150/${res?.userId}.png`,
      login: res?.login,
      userId: res?.userId, // 用户昵称，微信一开始没有真实名称，先把昵称保留在这
      accountType: res?.accountType,
      // project: moduleState?.project,
      // openId: moduleState?.openId,
      // // 统一用户管理表的用户信息
      // userName: moduleState?.user_name,
      // userId: moduleState?.user_id,
      // accountType: moduleState?.account_type,
      // defaultAppId: moduleState?.default_app_id,
      // authList: moduleState?.panguAuthInfo?.authList || [],
    });
    return {
      avatar: `http://r.hrc.woa.com/photo/150/${res?.userId}.png`,
      login: res?.login,
      userId: res?.userId, // 用户昵称，微信一开始没有真实名称，先把昵称保留在这
      accountType: res?.accountType,
    };
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

// cai表管理的用户信息
export const getCaiDataUserInfo = async (params: any, moduleState: any, actionCtx: IModuleCtx) => {
  const res = await get('api/user/getCaiDataUserInfo', params);

  actionCtx.setState({
    // woa登录信息
    // avatar: avatar,
    // login: moduleState?.login,
    // user: moduleState?.userId, // 用户昵称，微信一开始没有真实名称，先把昵称保留在这
    // project: moduleState?.project,
    // openId: moduleState?.openId,

    // 统一用户管理表的用户信息
    userName: res?.user_name,
    userId: res?.user_id,
    accountType: res?.account_type,
    defaultAppId: res?.default_app_id,
    authList: res?.panguAuthInfo?.authList || [],
  });
  // 这里不要删掉其他字段，会影响userName的渲染导致页面展示不出来
  return {
    ...res,
    userName: res?.user_name,
    userId: res?.user_id,
    accountType: res?.account_type,
    defaultAppId: res?.default_app_id,
    authList: res?.panguAuthInfo?.authList || [],
  };
};

// cai初始化用户
export const updateCaiUserInfo = async (params: any) => {
  const res = await post('api/user/updateCaiUserInfo', params);
  return res;
};

// 盘古权限申请
export const applyAuth = async (params: any) => {
  const res = await post('api/user/applyAuth', params);
  return res;
};

// 查询待审批用户列表
export const getApproveUserList = async (params: any) => {
  const res = await get('api/user/getApproveUserList', params);
  return res;
};

// 权限审批
export const approveUserList = async (params: any) => {
  const res = await post('api/user/approveUserList', params);
  return res;
};

// 存量用户权限审批
export const approveExsistedUserList = async (params: any) => {
  const res = await post('api/user/approveExsistedUserList', params);
  return res;
};
