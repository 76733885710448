import { useConcent } from 'concent';
import { Navigate } from 'react-router-dom';
// userAuths: 权限数组，auth_id：路由权限id，authWithPath：使用path建权
type IAuthRoute = {
  children: any;
  meta: Record<string, any> | null;
};
function AuthRoute(props: IAuthRoute) {
  const userCtx = useConcent({ module: 'user' });
  const { authList, userId } = userCtx.state;
  const { meta = {} } = props;
  if (meta?.white) {
    return props.children;
  }
  if (!userId || authList === null) {
    return null;
  }
  // 有用户Id查询后如果还没有权限查询返回，则跳转无权限页面
  return authList !== null && !authList?.length ? (
    <Navigate to="/exception-403" replace={true}></Navigate>
  ) : (
    props.children
  );
}

export default AuthRoute;
