import { CSSProperties, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
interface Props {
  children: ReactNode;
  isHttpUrl?: boolean; // false-站内跳转 true-站外跳转
  antdOptions?: any;
  style?: CSSProperties;
}

const ButtonLink = (props: Props) => {
  const { children = null, isHttpUrl = false, antdOptions = {}, style = {} } = props;
  const { type = 'link', href = '', target = '' } = antdOptions;
  const navigate = useNavigate();

  const linkTo = () => {
    navigate(href);
  };

  return isHttpUrl ? (
    <Button type={type} href={href} target={target} {...antdOptions} style={{ ...style }}>
      {children}
    </Button>
  ) : (
    <Button type={type} onClick={linkTo} {...antdOptions} style={{ ...style }}>
      {children}
    </Button>
  );
};

export default ButtonLink;
