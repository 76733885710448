import { get } from '../../utils/http';

export const getQaDataList = async (params: any) => {
  const res = await get('api/dataBoard/getQaDataList', params);
  return res;
};

export const getSysDataList = async (params: any) => {
  const res = await get('api/dataBoard/getSysDataList', params);
  return res || {};
};

export const getPerQaDataList = async (params: any) => {
  const res = await get('api/dataBoard/getPerQaDataList', params);
  return res;
};

export const getMyTaskList = async (params: any) => {
  const res = await get('api/dataBoard/getMyTaskList', params);
  return res;
};

export const getQaStatistics = async (params: any) => {
  const res = await get('api/dataBoard/getQaStatistics', params);
  return res;
};

export const getUserProductivity = async (params: any) => {
  const res = await get('api/dataBoard/getUserProductivity', params);
  return res;
};
