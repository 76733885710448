import React from 'react';
import Layouts from 'layouts/index';
import zhCN from 'antd/locale/zh_CN';
import { HashRouter as Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
const App = () => (
  <ConfigProvider
    locale={zhCN}
    theme={{
      token: {
        colorPrimary: '#007FFF',
        colorLink: '#007FFF',
      },
    }}
  >
    <Router>
      <Layouts />
    </Router>
  </ConfigProvider>
);
export default App;
