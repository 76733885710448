import { message } from 'antd';
import { uniqueData } from 'utils/common';
import { get, post } from '../../utils/http';

export const getProjectList = async (params: any) => {
  const res = await get('api/corpus/getProjectListUpgraded', params);
  return res;
};

export const getProjectDetail = async (params: any) => {
  const res = await get('api/corpus/getProjectDetail', params);

  return res;
};

export const modifyProjectInfo = async (params: any) => {
  try {
    const res = await post('api/corpus/modifyProjectInfo', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const delProject = async (params: any) => {
  try {
    const res = await post('api/corpus/delProject', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const modifyTaskInfo = async (params: any) => {
  try {
    const res = await post('api/corpus/modifyTaskInfo', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const modifyTaskList = async (params: any) => {
  try {
    const res = await post('api/corpus/modifyTaskList', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const deleteTaskInfo = async (params: any) => {
  try {
    const res = await post('api/corpus/deleteTaskInfo', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};
export const getTaskList = async (params: any) => {
  const res = await get('api/corpus/getTaskList', params);
  return res;
};
export const getTaskDetail = async (params: any) => {
  const res = await get('api/corpus/getTaskDetail', params);
  return res;
};
export const getMarkUsers = async (params: any) => {
  const res = await get('api/corpus/getMarkUsers', params);
  return res;
};

export const getUserList = async (params: any, moduleState: any, actionCtx: any) => {
  const res = await get('api/corpus/getUserList', params);
  const valArr: any[] = Object.values(res?.userListMap || {});
  const userListAll = [].concat(...valArr);
  const userList = uniqueData(userListAll, 'user_id');
  actionCtx.setState({ ...moduleState, userList });
  return res;
};

export const getThemeList = async (params: any, moduleState: any, actionCtx: any) => {
  const res = await get('api/corpus/getThemeList', params);
  actionCtx.setState({ ...moduleState, themeList: res || [] });
  return res;
};

export const getBotTypeList = async (params: any, moduleState: any, actionCtx: any) => {
  const res = await get('api/corpus/getBotTypeList', params);
  actionCtx.setState({ ...moduleState, botTypeList: res || [] });
  return res;
};

export const getRejectReasonList = async (params: any, moduleState: any, actionCtx: any) => {
  const res = await get('api/corpus/getRejectReasonList', params);
  actionCtx.setState({ ...moduleState, reasonList: res || [] });
  return res;
};

export const addSession = async (params: any) => {
  try {
    const res = await post('api/corpus/addSession', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const getQaList = async (params: any, moduleState: any, actionCtx: any) => {
  const res = await get('api/corpus/getQaList', params);
  actionCtx.setState({ ...moduleState, qaList: res?.qaList || [] });
  return res;
};

export const getSessionQaList = async (params: any, moduleState: any, actionCtx: any) => {
  const res = await get('api/corpus/getSessionQaList', params);
  actionCtx.setState({ ...moduleState, sessionQaList: res?.sessionQaList || [] });
  return res;
};

export const addQaList = async (params: any) => {
  try {
    const res = await post('api/corpus/addQaList', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const addImportSessionList = async (params: any) => {
  try {
    const res = await post('api/corpus/addImportSessionList', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const getCurStatusTasksByProId = async (params: any, moduleState: any, actionCtx: any) => {
  const res = await get('api/corpus/getCurStatusTasksByProId', params);
  actionCtx.setState({ ...moduleState, taskList: res?.taskList || [] });
  return res;
};

export const getBotList = async (params: any, moduleState: any, actionCtx: any) => {
  const res = await get('api/corpus/getBotList', params);
  actionCtx.setState({ ...moduleState, botList: res?.botList || [] });
  return res;
};

export const addBot = async (params: any) => {
  try {
    const res = await post('api/corpus/addBot', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};
export const addBotLists = async (params: any) => {
  try {
    const res = await post('api/corpus/addBotLists', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const updateTaskBotInfo = async (params: any) => {
  try {
    const res = await post('api/corpus/updateTaskBotInfo', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const passOrRejectByProId = async (params: any) => {
  try {
    const res = await post('api/corpus/passOrRejectByProId', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const updateTaskListInfo = async (params: any) => {
  try {
    const res = await post('api/corpus/updateTaskListInfo', params);
    return res;
  } catch (error) {
    message.error(error.message);
    return false;
  }
};

export const generateOcrData = async (params: any) => {
  try {
    const res = await post('api/corpus/generateOcrData', params);
    return res;
  } catch (error) {
    return false;
  }
};
// 获取QA信息
export const getQaDetail = async (params: any) => {
  try {
    const res = await get('api/corpus/getQaDetail', params);
    return res;
  } catch (error) {
    return false;
  }
};
